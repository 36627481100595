<template>
  <div class="row">
    <div class="col-12">
      <template v-if="readonly">
        <label v-if="!hideLabel" class="col-form-label">{{labelWithSuffix}}</label><p class="font-bold">{{ showValue }}</p>
      </template>
      <country-field v-if="!readonly && useCountry" :value="country" :country-name="country_name" :readonly="readonly" @input="onCountryCodeInput" @update:country="country_name=$event" />
      <ValidationProvider v-show="!readonly" name="address" :rules="rules" ref="valprov" :disabled="disableValidation">
        <b-form-group slot-scope="{ invalid, valid, validated, errors }" :label="labelWithSuffix"  :label-for="$attrs.id" :label-sr-only="hideLabel" :disabled="readonly">
          <b-form-input
            v-model="autocompleteText"
            name="address"
            autocomplete="off" 
            :disabled="disabled"
            :state="disableValidation ? null : (validated ? valid : null)"
            :placeholder="placeholder"
            ref="autocomplete"
            type="text"
            :id="id"
            @focus="onFocus()"
            @blur="onBlur()"
            @input="onInput"
            @change="onChange"
            @keypress="onKeyPress"
            @keyup="onKeyUp"
            ></b-form-input>
          <b-form-invalid-feedback><span v-for="(error, idx) in errors" :key="idx">{{error}}</span></b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
    </div>
    <div class="col-12">
      <b-form-group v-if="!readonly" label="Apartment, unit, suite, or floor #" label-for="address2">
        <b-form-input v-model="ourAddress2" id="address2" />
      </b-form-group>
    </div>
    <div v-if="useCountry && readonly" class="col-6">
      <country-field :value="country" :country-name="country_name" :readonly="readonly" @input="onCountryCodeInput" @update:country="country_name=$event" />
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from '@/shared/Components/VueGoogleAutocomplete.vue'
import CountryField from '@/shared/fields/country.vue'
import { formatAddress, formatAddressNoSuite } from '@/utils.js'
import '@/shared/validations.js'
export default {
  name: 'AddressLookup',
  extends: VueGoogleAutocomplete,
  components: { CountryField },
  props: {
    address: {
      type: Object
    },
    useCountry: {
      type: Boolean,
      default: false
    },
    countryName: {
      type: String
    },
    showAddressOnly: {
      type: Boolean,
      default: false
    },
    disableValidation: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'address'
    },
    label: {
      type: String,
      default: 'Address'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: 'required|min:5'
    },
    varPrefix: {
      type: String
    },
    fields: {
      type: Array,
      default() {
        return ['address_components', 'formatted_address', 'geometry']
      }
    },
    vars: {
      type: Object,
      default() {
        return { address: 'address', address2: 'address2', city: 'city', state: 'state', postal_code: 'zip_code', formatted_address: 'fullAddress' }
      }
    }
  },
  data() {
    return {
      value: null,
      country_name: null
    }
  },
  computed: {
    ourAddress2: {
      set(v) {
        this.setParentVarAndEmitChange('address2', v)
      },
      get() {
        if (this.address) {
          return this.address.address2
        }
      }
    },
    labelWithSuffix() {
      if (this.readonly) {
        return this.label
      }
      return this.label + '*'
    },
    showValue() {
      const addr = formatAddress(this.address)
      if (this.showAddressOnly) {
        return addr.addressPart
      }
      return addr.full
    }
  },
  methods: {
    handlePlaceChange(addressData, placeResultData, id) {
      console.log("handlePlaceChange", addressData, placeResultData, id)
      let newAddress = ""
      if (addressData.street_number) {
        newAddress = addressData.street_number
      }
      if (addressData.route) {
        newAddress += ' ' + addressData.route
      }
      if (newAddress) {
        this.setParentVarAndEmitChange('address', newAddress)
      }
      let city
      if (addressData.locality) {
        city = addressData.locality
      } else {
        if (addressData.neighborhood) {
          city = addressData.neighborhood
        }
      }
      if (city) {
        this.setParentVarAndEmitChange('city', city)
      } else {
        console.error('No city returned')
      }
      if ((addressData.country == "United States" || addressData.country == "Estados Unidos") && addressData.administrative_area_level_1 != addressData.locality) {
        this.setParentVarAndEmitChange('state', addressData.administrative_area_level_1)
      }
      if (addressData.postal_code) {
        this.setParentVarAndEmitChange('postal_code', addressData.postal_code)
      }
      if (placeResultData.formatted_address) {
        this.setParentVarAndEmitChange('formatted_address', placeResultData.formatted_address)
      }
      if (addressData.latitude) {
        this.$emit('update:field', { field: 'lat', value: addressData.latitude })
      }
      if (addressData.longitude) {
        this.$emit('update:field', { field: 'lng', value: addressData.longitude })
      }
    },
    onInput(e) {
      this.clearFields()
      this.$emit('input', e)
      this.$emit('update:field', { field: 'address', value: e })
    },
    onCountryCodeInput(e) {
      this.$emit('update:field', { field: 'country_code', value: e })
    },
    clearFields() {
      this.setParentVarAndEmitChange('formatted_address', null)
      this.setParentVarAndEmitChange('address', null)
      this.setParentVarAndEmitChange('city', null)
      this.setParentVarAndEmitChange('state', null)
      this.setParentVarAndEmitChange('postal_code', null)
      this.setParentVarAndEmitChange('country_code', null)
      this.$emit('update:field', { field: 'lng', value: null })
      this.$emit('update:field', { field: 'lat', value: null })
    },
    setParentVarAndEmitChange(varname, value) {
      const parentVarName = this.varPrefix ? (this.varPrefix + '.' + this.vars[varname]) : this.vars[varname]

      this.$emit('update:field', { field: parentVarName, value: value })
    },
  },
  watch: {
    address: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.city && this.value == null) {
          this.value = formatAddressNoSuite({ ...newValue, country: this.country_name }).full
        }
      }
    },
    countryName(newValue) {
      this.country_name  = newValue
    }
  }
}
</script>
